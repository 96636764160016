import React, { useState } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTimes } from '@fortawesome/free-solid-svg-icons';
import './FileUploadModal.css';

Modal.setAppElement('#root'); // Set the root element for accessibility

const FileUploadModal = ({ isOpen, onRequestClose, onSubmit }) => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [hasUploaded, setHasUploaded] = useState(false);
  const [answerType, setAnswerType] = useState('Plan of Care');
  const [fileContent, setFileContent] = useState('');

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFileContent(e.target.result);
      };
      reader.readAsText(file);
      setUploadedFile(file);
      setHasUploaded(true);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      setUploadedFile(file);
      setHasUploaded(true);
    }
  };


  const handleSubmit = (event) => {
    if (hasUploaded && answerType) {
        console.log('File uploaded:', uploadedFile);
        onSubmit(uploadedFile, answerType, fileContent);
        onRequestClose();
    } else {
        alert('Please upload Patient Summary and Medical Neccessity Assesment Type');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="File Upload"
      className="modal"
      overlayClassName="overlay"
    >
      <div className="modal-content">
      <div className="modal-header">
          <h3>Upload a File</h3>
            <FontAwesomeIcon
              icon={faTimes}
              className="close-icon"
              onClick={onRequestClose}
            />
        </div>
        <div
          className="file-drop-zone"
          onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()}
        >
          <label htmlFor="fileUpload" className="file-upload-label">
            <FontAwesomeIcon icon={faUpload} className="file-upload-icon" />
            {uploadedFile ? `Uploaded: ${uploadedFile.name}` : 'Drag and drop a file here or click to upload'}
          </label>
          <input type="file" id="fileUpload" onChange={handleFileUpload} className="file-upload-input" />
        </div>
        <div className="modal-header">
        <h3>Select Assessment Type</h3>
        </div>
        <div className="modal-select">
          <select
            id="answerType"
            value={answerType}
            onChange={(e) => setAnswerType(e.target.value)}
          >
            <option value="Plan of Care">Medical Neccessity for Plan of Care</option>
            <option value="Adjustments Required">Medical Neccessity for Plan of Care Adjustments Required</option>
            <option value="Medication">Medical Neccessity for Plan of Care and Medication</option>
          </select>
        </div>
        <div className="submit-button">
          <button 
          disabled={!hasUploaded} 
          onClick={handleSubmit}
          className={!hasUploaded ? 'disabled-button' : ''}>
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default FileUploadModal;
