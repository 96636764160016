import React, { useState, useEffect, useRef } from 'react';
import FileUploadModal from './FileUploadModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faDownload, faChevronLeft, faChevronRight, faUpload } from '@fortawesome/free-solid-svg-icons';
import './App.css';
import ReactMarkdown from 'react-markdown';
import logo from './logo.png';

function App() {
  const [collapsed, setCollapsed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [answerType, setAnswerType] = useState('');
  const [fileContent, setFileContent] = useState('');
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [messages, setMessages] = useState([]);
  const chatBoxRef = useRef(null);
  const [isTyping, setIsTyping] = useState(false);
  const [userPaused, setUserPaused] = useState(false);
  const [startNew, setStartNew] = useState(false);
  const [assesment, setAssesment] = useState('Please start a "New Assesment" and upload a file to begin!');

  useEffect(() => {

    // This code runs after selectedOption has been updated
    processNewOption(answerType);

  }, [answerType]);

  const processNewOption = (option) => {
    // Perform your processing here
    console.log('Processing new option:', option);
    setAnswerType(option);
  };


  useEffect(() => {

    // This code runs after selectedOption has been updated
    processNewText(fileContent);

  }, [fileContent]);

  const processNewText = (option) => {
    // Perform your processing here
    setFileContent(option);
  };

  function isFileReadable(file) {
    const readableTypes = ['text/plain', 'application/json', 'text/csv', 'text/html', 'text/markdown'];
    return readableTypes.includes(file.type);
  }
  
  function readFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => resolve(event.target.result);
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  }

  const handleInputChange = (e) => setInputValue(e.target.value);

  const handleKeyDown = async (e) => {
    if (e.key === 'Enter' && inputValue.trim() !== '') {
      setMessages([...messages, { text: inputValue, sender: 'user' }]);
      setInputValue('');
      setIsTyping(true);
      setUserPaused(true);
      // const response = handleFile(uploadedFile);

      try {
        const url = `https://salama-backend-cg7yr5r27a-ww.a.run.app/chat/response`;
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_message: inputValue, history: messages, file_text: fileContent}),
        });
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const data = await response.json();
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: data, sender: 'ai' }
        ]);
      } catch (error) {
        console.error('Error fetching item:', error);
      }
      setIsTyping(false);
      setUserPaused(false);


      // Simulate AI response
      // setTimeout(() => {
      //   setMessages((prevMessages) => [
      //     ...prevMessages,
      //     { text: '#### This is an AI response.', sender: 'ai' }
      //   ]);
      //   setIsTyping(false);
      //   setUserPaused(false);
      // }, 2000); // Simulate delay for AI response
    }
  };

  const formatMessage = (text) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleFileUpload = async (file, value, content) => {
    //on submit- wipe previous messages
    setMessages([]);
    // processNewOption(value);
    // processNewText(content);

    setUploadedFile(file);
    setAnswerType(value);
    console.log(value);
    setFileContent(content);
    hideTooltip();

    //call API here
    setIsTyping(true);
    setUserPaused(true);
    // const response = handleFile(uploadedFile);
    


    try {
      const url = `https://salama-backend-cg7yr5r27a-ww.a.run.app/assesment/extracted`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text: content, answerType: value }),
      });
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.json();
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: data, sender: 'ai' }
      ]);
      setAssesment(data);
    } catch (error) {
      console.error('Error fetching item:', error);
    }
    setIsTyping(false);
    setUserPaused(false);
  };

  const newChat = () => {
    toggleModal();
  };

  const downloadChat = () => {
    if (chatBoxRef.current) {
      const content = chatBoxRef.current.innerHTML;
      const blob = new Blob([content], { type: 'text/html' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'chat-history.html';  // You can change the filename here
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);  // Clean up to avoid memory leaks
    }
  }

  const showTooltip = () => {
    setTooltipVisible(true);
  };

  const hideTooltip = () => {
    setTooltipVisible(false);
  };

  return (
    <div className="App">
    <FileUploadModal 
    isOpen={isModalOpen} 
    onRequestClose={toggleModal} 
    onSubmit={handleFileUpload}
    />

      <div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
        <div className="sidebar-header">
          <div className="sidebar-logo">
              <img className="logo" src={logo} alt="Logo" style={{ width: '50px', height: 'auto', margin: '0px'}}/>
              {collapsed ? '' : <h4 className="text">Salama</h4>}
          </div>
          <div className="sidebar-toggle" onClick={toggleSidebar}>
            <FontAwesomeIcon icon={collapsed ? faChevronRight : faChevronLeft}/>
          </div>
        </div>
        <div className="sidebar-content">
          {!collapsed && (
              <div className="file-upload-container">

              </div>
            )}
        </div>
        <div className="sidebar-buttons">
          <button onClick={newChat}>
            {collapsed ? <FontAwesomeIcon icon={faPlus} /> : 'New Assessment'}
          </button>
          <button onClick={downloadChat}>
            {collapsed ? <FontAwesomeIcon icon={faDownload} /> : 'Download Chat'}
          </button>
        </div>
      </div>
      <div className={`chat-container ${collapsed ? 'collapsed' : ''}`} >
        <div className="chat-box" ref={chatBoxRef}>
        {messages.map((message, index) => (
          <div key={index} className={`chat-bubble ${message.sender === 'user' ? 'right' : 'left'}`}>
            {message.sender === 'ai' ? (
            <ReactMarkdown>{message.text}</ReactMarkdown>
          ) : (
            <div>{message.text}</div>
          )}
          </div>
        ))}
        
        {isTyping && (
          <div className="chat-bubble left typing">
            <div className="typing-dot"></div>
            <div className="typing-dot"></div>
            <div className="typing-dot"></div>
          </div>
        )}
        </div>
        <div className="chat-input-container" onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
          <input type="text"
           className="chat-input" 
           placeholder="Type your message..." 
           value={inputValue}
           onChange={handleInputChange}
           onKeyDown={handleKeyDown}
           disabled={!uploadedFile || !answerType || userPaused}
           />
          {tooltipVisible && (!uploadedFile || !answerType) && (
            <div className="tooltip">
              Please upload a file and select an assesment type to activate chat. Start by clicking the "New Assessment" button.
            </div>
          )}

        </div>
      </div>
    </div>
  );
}

export default App;
